
import "../../styles/components/CTAs.scss";

//updated TrainingDataCTA
export const trainingDataCTA = (
    heading = "From <strong>scaling to enhancing</strong> your model development with data-driven insights"
  ) => {
    return `
        <section class="flex flex-col-reverse sm:flex-row gap-3.5 sm:gap-10 lg:gap-3.5 p-6 px-4 sm:px-7 items-center py-6 sm:py-10 lg:py-5 lg:px-10 rounded-[14px] Training_data_CTA_dark_bg">
        <section class="flex flex-col flex-1 items-center sm:items-start justify-center relative z-10 w-full lg:flex-1 heading_width">
  
      <div class="text-2xl sm:text-2.5xl max-w-sm 2xl:max-w-none font-semibold tracking-[-1.5px] text-white font-manrope sm:text-start text-center Training_data_CTA_dark_strong ">${heading}</div>
        <a
            href="https://encord.com/try-it-free/?&utm_campaign=cta-blog-encord-light"
            target="_blank"
            rel="noopener noreferrer"
            class="pb-2 rounded-2.5xl flex justify-center sm:justify-start mt-4 sm:mt-6 hover:no-underline demo-cta"
          >
            <button class="bg-white px-[16px] py-[8px] sm:px-[16px] sm:py-[8px] rounded-full  text-base tracking-[-0.4px] font-semibold  text-purple-1100 font-manrope">
          Learn more
            </button>
          </a>
        </section>
  
          <section class="z-10 sm:[203px] lg:h-[250px]">
            <img
              src="https://images.prismic.io/encord/Zky98iol0Zci9U3b_tryEncordCTADark.png?auto=format,compress"
              height="250"
              width="331"
              alt="medical banner"
              class="h-full object-contain"
            />
          </section>
      </section>
          `;
  };
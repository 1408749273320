import React, { useContext } from "react";
import { Chevron } from "../../../assets/icons";
import CustomButton from "../../CustomButton";
import HeadingSection from "../HeadingSection";
import profile from "../../../assets/slices/testiProfile.svg";
import ModalContext from "../../../Contexts/openModal";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const TestimonialMonetCard = ({ data }) => {
  const { toggleModal } = useContext(ModalContext);
  
  return (
    <div
      className={` text-white max-w-[798px] w-auto mx-auto rounded-lg sm:px-12 lg:px-0 px-4`}
    >
      <div className="flex justify-center">
        <HeadingSection
          data={data?.primary}
          headingStyle={`${data?.primary?.theme === "Dark" ? "text-white" : " text-purple-1100"}`}
          sectionStyle={"items-center"}
          labelStyle={`${data?.primary?.theme === "Dark" ? "text-purple-350" : " text-purple-1500"}`}
          toggleModal={toggleModal}
        />
      </div>
      <div className="flex items-center gap-2.5 sm:hidden mb-5">
        <img
          // image={getImage(data?.items?.author_image)}
          src={data?.items[0]?.author_image?.url}
          alt="Profile"
          loading="lazy"
          className=" sm:w-[76px] w-[60px] rounded-full "
        />
        <div className="">
          <h1
            className={`text-sm sm:text-lg font-semibold ${data?.primary?.theme === "Dark" ? "text-white" : "text-gray-2700"} `}
          >
            {data?.items[0]?.author_name?.text}
          </h1>
          <p
            className={`text-xs sm:text-base font-normal ${data?.primary?.theme === "Dark" ? "text-gray-1400" : "text-gray-600"} max-w-[240px]`}
          >
            {data?.items[0]?.author_job_designation?.text}
          </p>
        </div>
      </div>
      <div className={`sm:pl-20 p-0 mb-5 sm:mb-2.5`}>
        <p
          className={`lg:px-10 sm:px-8 px-6 lg:py-9 py-6 sm:py-6 font-medium sm:text-xl text-lg md:tracking-[-0.4px] sm:tracking-[-0.5px] tracking-[-0.4px] ${data?.primary?.theme === "Dark" ? "bg-[#FAFAFA]  " : "bg-[#F0F5FF] "}  text-[#262626] rounded-tl-[2px] sm:rounded-tl-[30px] lg:rounded-tl-[44px] rounded-tr-[30px] lg:rounded-tr-[44px] rounded-br-[30px] lg:rounded-br-[44px] rounded-bl-[30px] sm:rounded-bl-[2px] lg:rounded-bl-[2px]`}
        >
          {data?.items[0]?.testimonial_text?.text}
        </p>
      </div>

      <div className="flex items-center justify-between">
        <div className="sm:flex items-center gap-2.5 hidden">
          <img
            // image={getImage(data?.items?.author_image)}
            src={data?.items[0]?.author_image?.url}
            alt="Profile"
            loading="lazy"
            className=" sm:w-[76px] w-[60px] rounded-full "
          />
          <div className="">
            <h1
              className={`text-sm sm:text-lg font-semibold ${data?.primary?.theme === "Dark" ? "text-white" : "text-gray-2700"}`}
            >
              {data?.items[0]?.author_name?.text}
            </h1>
            <p
              className={`text-xs sm:text-base font-normal ${data?.primary?.theme === "Dark" ? "text- gray-1400" : "text-gray-600"} max-w-[320px]`}
            >
              {data?.items[0]?.author_job_designation?.text}
            </p>
          </div>
        </div>
        <CustomButton
          link={data?.items[0]?.cta_link?.url}
          text={data?.items[0]?.cta_text?.text}
          buttonClass="bg-gray-1600 text-gray-2500 w-full lg:w-auto"
          icon={<Chevron />}
          direction="right"
        />
      </div>
    </div>
  );
};

export default TestimonialMonetCard;

import React, { useRef } from "react";
import CompanyCard from "./CompanyCard";
import "../../styles/components/CTAs.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Mousewheel } from "swiper/modules";
import { useMediaQuery } from "react-responsive";

const NextArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="43"
    viewBox="0 0 42 43"
    fill="none"
  >
    <path
      d="M19.6977 15.6593C19.3433 15.305 18.792 15.305 18.4377 15.6593C18.0833 16.0137 18.0833 16.565 18.4377 16.9193L23.0445 21.605L18.4377 26.2906C18.0833 26.645 18.0833 27.1962 18.4377 27.5506C18.5952 27.7081 18.8314 27.7868 19.0677 27.7868C19.3039 27.7868 19.5402 27.7081 19.6977 27.5112L24.9345 22.1956C25.2889 21.8412 25.2889 21.29 24.9345 20.9356L19.6977 15.6593Z"
      fill="#595959"
    />
  </svg>
);
const PrevArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
  >
    <path
      d="M22.3023 14.9689C22.6567 14.6145 23.208 14.6145 23.5623 14.9689C23.9167 15.3233 23.9167 15.8745 23.5623 16.2289L18.9555 20.9145L23.5623 25.6002C23.9167 25.9545 23.9167 26.5058 23.5623 26.8602C23.4048 27.0177 23.1686 27.0964 22.9323 27.0964C22.6961 27.0964 22.4598 27.0177 22.3023 26.8208L17.0655 21.5052C16.7111 21.1508 16.7111 20.5995 17.0655 20.2452L22.3023 14.9689Z"
      fill="#595959"
    />
  </svg>
);

const CompareChartVersion = ({ slice, toggleModal }) => {
  const sliderRef = useRef();
  const topSpacing = slice?.primary?.top_spacing;
  const bottomSpacing = slice?.primary?.bottom_spacing;
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isLargeDesktop = useMediaQuery({ minWidth: 1200 });
  const isSmallDesktop = useMediaQuery({ minWidth: 1050 });
  const maxWidth = isLargeDesktop
    ? "560px"
    : isSmallDesktop
      ? "430px"
      : isDesktop
        ? "400px"
        : "100%";

  const encordObject = slice?.items.find((item) => item.company_name.text === "Encord");
  const otherObjects = slice?.items.filter(
    (item) => item.company_name.text !== "Encord"
  );
  return (
    <section
      className={`bg-gray-2800 compare_chart_version_style ${topSpacing === "80" && "sm:pt-20 pt-8"} ${topSpacing === "40" && "sm:pt-10 pt-8"} ${topSpacing === "0" && "pt-0"} ${bottomSpacing === "80" && "sm:pb-20 pb-8"} ${bottomSpacing === "40" && "sm:pb-10 pb-8 "} ${bottomSpacing === "0" && "pb-0"}`}
    >
      {isDesktop ? (
        <div className="flex flex-col gap-6">
          <div className="flex items-center justify-between">
            <p className="compare_chart_version_heading">
              {slice?.primary?.cta_heading?.text}
            </p>
            {slice?.items.length > 3 && (
              <section className="flex items-center justify-center gap-3 custon_button_disable">
                <button
                  className="custom-prev-button "
                  onClick={() => {
                    if (sliderRef.current && sliderRef.current.swiper) {
                      sliderRef.current.swiper.slidePrev();
                    }
                  }}
                >
                  <PrevArrow />
                </button>
                <button
                  className="custom-next-button"
                  onClick={() => {
                    if (sliderRef.current && sliderRef.current.swiper) {
                      sliderRef.current.swiper.slideNext();
                    }
                  }}
                >
                  <NextArrow />
                </button>
              </section>
            )}
          </div>
          <div className="flex gap-[14px]">
            <div className="w-full  encord_card_width">
              <CompanyCard
                cardData={encordObject}
                toggleModal={toggleModal}
              />
            </div>
            <Swiper
              direction="horizontal"
              slidesPerView={2}
              spaceBetween={14}
              mousewheel={{
                forceToAxis: true,
              }}
              forceToAxis={true}
              navigation={{
                nextEl: ".custom-next-button",
                prevEl: ".custom-prev-button",
              }}
              modules={[Navigation, Mousewheel]}
              className="mySwiper"
              style={{
                maxWidth,
                margin: 0,
                paddingBottom: 0,
              }}
            >
              {otherObjects?.map((card, key) => (
                <SwiperSlide key={key}>
                  <CompanyCard cardData={card} toggleModal={toggleModal} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      ) : (
        <>
          <p className="compare_chart_version_heading pb-6">
            {slice?.primary?.cta_heading?.text}
          </p>
          <div className="flex flex-col gap-3.5">
            {slice?.items?.map((card, key) => (
              <CompanyCard
                cardData={card}
                key={key}
                toggleModal={toggleModal}
              />
            ))}
          </div>
        </>
      )}
    </section>
  );
};

export default CompareChartVersion;

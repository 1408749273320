
import "../../styles/components/CTAs.scss";

export const tryEncordCTAAnnotateVisual = () => {
    return ` <section class="flex flex-col-reverse sm:flex-row gap-3 2xl:gap-4 my-9 rounded-2.5xl px-5 2xl:px-8 cta_bg_dark">
      <section class="flex flex-col flex-1 justify-center py-10 text-white">
        <div class="text-2xl xl:text-xl lgxl_mid:text-xl 2xl:text-2xl max-w-sm 2xl:max-w-none font-semibold">
        Scale your annotation workflows and power your model performance with data-driven insights
        </div>
  
        <a
            href="https://encord.com/try-it-free/?&utm_campaign=cta-blog-encord-dark"
          target="_blank"
          rel="noopener noreferrer"
          class="pb-2 rounded-2.5xl flex mt-6 demo-cta"
        >
          <button class="text-purple-500 px-[20px] py-[5px] sm:px-[38px] sm:py-[14px] rounded-full sm:text-[14.94px] text-2xs leading-[18.08px] font-bold bg-white">
            Try Encord today
          </button>
        </a>
      </section>
  
      <section class="flex-1 mt-6">
        <img
          src="https://images.prismic.io/encord/400c35fb-0e12-4958-b46f-9c9ce7054134_cta-img.png?auto=compress,format"
          height="747"
          width="949"
          alt="medical banner"
          class="h-full"
        />
      </section>
    </section>
      `;
  };

import "../../styles/components/CTAs.scss";

export const BlogCtaTofu1 = () => {
    return `
    <div class="ctaTofu1_border_animation border border-gray-1600 p-[1px] my-9">
    <section class="flex flex-col-reverse sm:flex-row-reverse gap-3 2xl:gap-4  items-center  sm:pl-8">
  
        <section class="flex-[0.5] flex justify-center items-center py-5 tofu_bg_image ">
          <img 
            src="https://encord.cdn.prismic.io/encord/Zvzpw7VsGrYSwQ5z_image147.svg" 
            height="220" 
            width="280" 
            alt="medical banner" 
            class="h-full "
          />
        </section>
      <section class="flex flex-col flex-[0.5] justify-center gap-4 pt-5 sm:pt-0 px-4 md:px-0">
      <p
              class="inline !text-xs !font-semibold !uppercase !font-manrope !-tracking-tightest_5 !text-purple-1500 !my-0"
            >
              AI data readiness report
            </p>
      <div class="!text-2.5xl sm:!text-3.25xl !leading-10 !tracking-tightest_7 !text-purple-1100 !font-semibold">Maximizing AI ROI:<br class="hidden sm:block"/>
  Why Data Matters Most</div>
      
      <a
          href="https://encord.com/ai-data-readiness-report/?&utm_campaign=Blog-CTA-TOFU"
          target="_blank"
          rel="noopener noreferrer"
          class="max-w-fit"
        >
          <button class="text-white px-3 py-2 rounded-full text-sm tracking-tighter font-semibold !font-manrope bg-purple-1500">
            Read the report
          </button>
        </a>
    </section>
    </div>
    `;
  };
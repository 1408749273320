import React, { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { Helmet } from "react-helmet";
import { Toaster } from "react-hot-toast";
import { EA_MODAL_PAGES } from "../constants";
import Footer from "../partials/Footer";
import Header from "../partials/Header";
import CustomLink from "./CustomLink";
import EaModal from "./EaModal";
import { Script } from "gatsby";

export default function Layout({
  children,
  location,
  setIsMobileMenuOpen = null,
  hideFooter = false,
  hideHeader = false,
  mainClasses,
  theme = "default",
  isStickyCTA,
  loadLayoutWithScriptsOnly = false,
  dataMode = false,
  overflow,
  layoutClasses = "",
}) {
  const [scrollY, setScrollY] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en-GB" }}>
        {/* FevIcons Start */}
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        <link rel="icon" type="image/png" href="/favicon.svg" />
        {/* FevIcons End */}

        <link rel="preconnect" href="https://dev.visualwebsiteoptimizer.com" />
        <script>{`!function () {var reb2b = window.reb2b = window.reb2b || [];if (reb2b.invoked) return;reb2b.invoked = true;reb2b.methods = ["identify", "collect"];reb2b.factory = function (method) {return function () {var args = Array.prototype.slice.call(arguments);args.unshift(method);reb2b.push(args);return reb2b;};};for (var i = 0; i < reb2b.methods.length; i++) {var key = reb2b.methods[i];reb2b[key] = reb2b.factory(key);}reb2b.load = function (key) {var script = document.createElement("script");script.type = "text/javascript";script.async = true;script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";var first = document.getElementsByTagName("script")[0];first.parentNode.insertBefore(script, first);};reb2b.SNIPPET_VERSION = "1.0.1";reb2b.load("GOYPYHVEWROX");}();`}</script>
        <script
          async
          defer
          src="https://static.cdn.prismic.io/prismic.js?new=true&repo=encord"
        />
        <script src="https://tag.clearbitscripts.com/v1/pk_3adfa5e57fcda43370d4da97618f3856/tags.js" referrerpolicy="strict-origin-when-cross-origin"></script>
        <script defer src="https://app.revenuehero.io/scheduler.min.js" />

        <script type="text/javascript" defer>
          {`(window.__positional_config = {
              customerId: "fe4a1f3c-03e2-4300-87c4-ed77d929792b",
            })`}
        </script>

        <script
          defer
          src="https://assets.positional-bucket.com/positional.min.js"
        />
        <script>{`!function(){window.unify||(window.unify=Object.assign([],["identify","page","startAutoPage","stopAutoPage","startAutoIdentify","stopAutoIdentify"].reduce((function(t,e){return t[e]=function(){return unify.push([e,[].slice.call(arguments)]),unify},t}),{})));var t=document.createElement("script");t.async=!0,t.setAttribute("src","https://tag.unifyintent.com/v1/Mbxuo3zAAkK3H9z12wjknb/script.js"),t.setAttribute("data-api-key","wk_V7wtnR3b_7fNmgTCBTQacx8CMbkMuDgQ6RzQC8UF2"),t.setAttribute("id","unifytag"),(document.body||document.head).appendChild(t)}();`}</script>
      </Helmet>
      {!loadLayoutWithScriptsOnly ? (
        <div
          className={`flex flex-col min-h-screen overflow-hidden ${layoutClasses}`}
        >
          {/*  Site header */}
          {!hideHeader && (
            <Header
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              location={location}
              theme={
                theme === "transparent"
                  ? scrollY < 72
                    ? "transparent"
                    : "default"
                  : theme
              }
            />
          )}
          {/*  Page content */}
          <main
            className={`flex-grow ${overflow && "overflow-hidden"} ${dataMode ? "dark" : "light"
              } ${mainClasses}`}
          >
            <Toaster position="top-right" reverseOrder={false} />
            {children}
          </main>

          {/*  Site footer */}
          {!hideFooter && <Footer theme={theme} isStickyCTA={isStickyCTA} />}

          <section className="fixed bottom-0 w-full z-[999] flex flex-col">
            {isStickyCTA && (
              <section className="flex justify-center py-3 bg-white shadow-lg sm:hidden backdrop-blur">
                <CustomLink
                  to={"/try-it-free/"}
                  className="btn w-80 text-white bg-[#4F33CA] shrink-0 rounded-full font-semibold border border-[#4F33CA]"
                >
                  Get started
                </CustomLink>
              </section>
            )}

            <CookieConsent
              location="bottom"
              buttonText="Accept"
              cookieName="myAwesomeCookieName2"
              contentClasses="!flex-none"
              style={{ background: "#2B373B", justifyContent: "start" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              buttonClasses="!mx-0 sm:mx-[15px]"
              expires={150}
              containerClasses={`flex-col sm:flex-row ${isStickyCTA && "sm:!fixed !relative"
                }`}
            >
              This website uses cookies to enhance the user experience.{" "}
            </CookieConsent>
          </section>
          {EA_MODAL_PAGES.includes(location?.pathname) && (
            <EaModal location={location} />
          )}
        </div>
      ) : (
        children
      )}
    </>
  );
}

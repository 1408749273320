
import "../../styles/components/CTAs.scss";

export const productActiveCTA = () => {
    return `<section class="flex-1 max-w-full p-5 border-t border-b border-[#EDE2FF] space-y-7 drop-shadow-xl">
        <div class="text-xl font-semibold lg:text-2xl md:text-xl text-center">
          Evaluate Models and Build Active Learning Pipelines with Encord
        </div>
        <video loop autoPlay muted class="w-auto h-auto rounded-3.5xl">
          <source
            src="https://prismic-io.s3.amazonaws.com/encord/7069864e-d82f-40b6-b13f-86a74d7c2411_Visual-search-Twitter-Post-comp.mp4"
            type="video/mp4"
          />
        </video>
        <a
          href="https://encord.com/try-it-free/?&utm_campaign=cta-blog-product-active"
          target="_blank"
          rel="noopener noreferrer"
          class="pb-2 rounded-2.5xl flex justify-center demo-cta"
        >
          <button class="text-purple-500 px-[20px] border-2 border-purple-500 py-[5px] sm:px-[38px] sm:py-[14px] rounded-full sm:text-[14.94px] text-2xs leading-[18.08px] font-bold">
            Try Encord today
          </button>
        </a>
      </section>`;
  };

import "../../styles/components/CTAs.scss";


export const tryOnGithubBar = () => {
    return `
          <div class="pb-[15px] pr-[13px] flex-1 flex flex-col !text-white z-10 ">       
              <a class="!text-white mt-12 demo-cta" href="https://github.com/encord-team/encord-active/" target="_blank">
                <button class="bg-purple-500 px-[38px] py-[14px] rounded-full text-[14.94px] leading-[18.08px] font-bold mx-auto">Try on GitHub</button>
              </a>
          </div>
          `;
  };
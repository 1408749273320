
import "../../styles/components/CTAs.scss";

export const fixErrorsCTA = () => {
    return `
        <section class="bg-purple-1000 lg:rounded-3xl md:rounded-2xl rounded-xl lg:p-10 p-5 lg:max-w-7xl md:max-w-2xl max-w-[300px] mx-auto flex lg:flex-row flex-col-reverse w-full lg:justify-between lg:my-4 my-2">
          <div class="flex flex-col max-w-xl w-full justify-center lg:pb-0 pb-4">
            <div class="text-white lg:text-3xl md:text-2xl text-xl   font-normal">
              Find and fix dataset errors and biases.
            </div>
            <a class="text-white mt-12 lg:flex block demo-cta" href="https://github.com/encord-team/encord-active/" target="_blank">
              <button class="bg-gray-2400 lg:px-9 px-5 py-3 rounded-full md:text-xl text-lg font-normal text-blue-1100">
                Try Encord Active
              </button>
            </a>
          </div>
          <img
            src="https://encord.cdn.prismic.io/encord/8d6a8271-eb3b-4cff-b90a-41099dba48d9_Encord+active.svg"
            alt="encord-active"
            loading="lazy"
            class="lg:max-w-[50%] w-full lg:pb-0 pb-4 h-auto"
            width={0}
            height={0}
          />
        </section>`;
  };
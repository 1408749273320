import React, { useState } from "react";
import DetailedProductAccordian from "./DetailedProductAccordian";
import SlicesVideoComponent from "../Slices/SlicesVideoComponent";
import { AnimatePresence, motion } from "framer-motion";

const DetailedProductVersion = ({ slice }) => {
  const tabs = slice.map((item) => ({
    tabName: item?.primary?.product_title?.text || "",
    icon: item?.primary?.tab_icon?.url || "",
  }));
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className="flex flex-col gap-[22px]">
      <p className="!text-2.5xl !text-purple-1100 font-semibold font-manrope tracking-[0.75px] text-center">
        Choose what’s right for you
      </p>
      <div className="flex gap-2.5 sm:gap-5 p-1 bg-gray-1500 max-w-fit rounded-full mx-auto justify-center">
        {tabs.map((value, index) => (
          <div
            className={`flex gap-2.5 cursor-pointer px-4 sm:px-6 py-2 items-center rounded-full relative ${
              selectedTab === index ? "bg-white" : ""
            }`}
            key={index}
            onClick={() => setSelectedTab(index)}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <AnimatePresence>
              {hoveredIndex === index && (
                <motion.span
                  className="absolute inset-0 h-full w-full bg-white dark:bg-slate-800/[0.8] block rounded-full z-0"
                  layoutId="hoverBackground"
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 0.57,
                    transition: { duration: 0.15 },
                  }}
                  exit={{
                    opacity: 0,
                    transition: { duration: 0.15, delay: 0.2 },
                  }}
                />
              )}
            </AnimatePresence>
            <img
              className="w-6 h-6 sm:block hidden z-10 relative"
              src={value?.icon}
              alt={value?.tabName || "tab icon"}
            />
            <span className="z-10 font-semibold text-base sm:text-[20px] font-manrope tracking-tightest text-gray-600">
              {value?.tabName}
            </span>
          </div>
        ))}
      </div>

      {selectedTab !== null && (
        <div className="flex flex-col sm:flex-row pt-3 pb-3.5 px-3 gap-[19px] rounded-md detail_product_version_border transition-all duration-75">
          <div className="flex flex-col gap-[11px]">
            {slice[selectedTab]?.primary?.product_asset_image?.url && (
              <motion.img
                className="sm:max-w-[232px]"
                src={slice[selectedTab]?.primary?.product_asset_image?.url}
                alt={
                  slice[selectedTab]?.primary?.product_asset_image?.alt ||
                  "Product asset"
                }
              />
            )}
            {slice[selectedTab]?.primary?.product_asset_video?.url && (
              <SlicesVideoComponent
                loop
                autoPlay
                muted
                videoComponentClass={`rounded-md flex-shrink-0 aspect-square max-h-[227px] sm:max-w-[232px] min-w-[232px] h-full w-full`}
                src={slice[selectedTab]?.primary?.product_asset_video?.url}
              />
            )}

            <div className="flex gap-[9px] items-center ">
              <img
                className="size-9"
                src={slice[selectedTab]?.primary?.product_icon?.url}
                alt={
                  slice[selectedTab]?.primary?.product_icon?.alt ||
                  "Product Icon"
                }
              />
              <div className="product_title_style">
                <h4 className="my-0">
                  {slice[selectedTab]?.primary?.product_title?.text}
                </h4>
                {slice[selectedTab]?.primary?.product_description?.text && (
                  <p className="my-0">
                    {slice[selectedTab]?.primary?.product_description?.text}
                  </p>
                )}
              </div>
            </div>
            <div className="flex gap-1.5 product_tag_style">
              {slice[selectedTab]?.primary?.product_tag_1?.text && (
                <p>{slice[selectedTab]?.primary?.product_tag_1?.text}</p>
              )}
              {slice[selectedTab]?.primary?.product_tag_2?.text && (
                <p>{slice[selectedTab]?.primary?.product_tag_2?.text}</p>
              )}
            </div>
          </div>
          <div className="w-full">
            {slice[selectedTab]?.items.map((featuredata, index) => (
              <DetailedProductAccordian
                accordionData={featuredata}
                key={index}
                isFirstItem={index === 0}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailedProductVersion;

import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { HUBSPOT_PROXY_URL, emailRegex } from "../../../constants";

import "../../../styles/components/Slices/FormKusama.scss";
import {
  isDomainBlocked,
  isEducationalEmail,
  USE_CASE_TEXT_VALIDATION,
  WORK_EMAIL_VALIDATION,
} from "../../../utilities/helpers";
import LogoMarquee from "../../LogoMarquee";
import SlicesStepper from "../../SlicesStepper";

const SingleEmailFormFormSchema = Yup.object().shape({
  email: WORK_EMAIL_VALIDATION,
});
const RequestTrialSchema = Yup.object().shape({
  workEmail: WORK_EMAIL_VALIDATION,
  useCaseFreeTextForm: USE_CASE_TEXT_VALIDATION,
});
const SingleEmailForm = ({ handleNext, formClass }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (emailRegex.test(values?.email) && !isDomainBlocked(values?.email)) {
        setLoading(true);
        const requestPayload = {
          fields: [
            {
              objectTypeId: "0-1",
              name: "email",
              value: values?.email,
            },
          ],
        };

        await axios.post(
          HUBSPOT_PROXY_URL,
          Object.assign(requestPayload, {
            portalId: "25381551",
            formId: "c90a5ae1-1852-43f5-9346-a57837b9f470",
          })
        );

        resetForm();
        localStorage.setItem("workEmail", values?.email);
        handleNext();
      }
    } catch (error) {
      console.log("error while submitting form");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={SingleEmailFormFormSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        touched,
        values,
        setTouched,
        validateForm,
        setFieldValue,
        form,
        isValid,
        dirty,
        ...rest
      }) => {
        return (
          <Form className={`${formClass} mt-3.5`}>
            <div className="flex flex-col flex-1 w-full mt-4 lg:mt-2.5">
              <label className="text-gray-2500 placeholder:text-[#a8a4a4] text-sm font-inter tracking-tighter_2 font-medium mb-1.5">
                Work email
                <sup className="text-red-300">*</sup>
              </label>
              <Field
                name="email"
                placeholder="Enter your work email"
                type="text"
                className="text-gray-600 placeholder:text-[#BFBFBF] text-base sm:text-sm font-inter tracking-tighter_2 px-5 py-2.5 font-normal rounded-lg placeholder-gray-2200 border-solid border-gray-2200 border"
              />
              {errors.email && touched.email ? (
                <small className="text-red-300">{errors.email}</small>
              ) : null}
            </div>

            <div className="flex gap-5">
              <button
                type={"submit"}
                disabled={loading || !(isValid && dirty)}
                className={`disabled:cursor-not-allowed bg-[#5E44FF] font-semibold font-manrope tracking-tighter_3 border-[#5E44FF] text-white py-2 text-base shadow-lg rounded-full w-full mx-auto my-4 lg:mt-6 lg:mb-5 hover:border-[#5E24FF] hover:bg-[#5E24FF]`}
                {...rest}
              >
                <div className="flex justify-center items-center gap-2.5">
                  Get started
                  {loading && (
                    <div className="border border-white border-solid rounded-full size-4 animate-spin border-t-transparent" />
                  )}
                </div>
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const DemoForm = ({ handleNext, formClass }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const workEmail = localStorage.getItem("workEmail");
  const handleSubmit = async (values, { resetForm }) => {
    const formId = "46cb4fb8-a5b7-404a-82a8-6b0854d4f89b";

    const [cookie] = document.cookie
      .split("; ")
      .filter((x) => x.includes("hubspotutk="));
    const [, hubspotutk] = cookie?.split("=") || ["", ""];
    setError("");
    try {
      if (emailRegex.test(values?.email) && !isDomainBlocked(values?.email)) {
        setLoading(true);

        const requestPayload = {
          fields: [
            {
              objectTypeId: "0-1",
              name: "firstname",
              value: values?.firstname,
            },
            {
              objectTypeId: "0-1",
              name: "lastname",
              value: values?.lastname,
            },
            {
              objectTypeId: "0-1",
              name: "email",
              value: values?.workEmail,
            },
            {
              objectTypeId: "0-1",
              name: "phone_number__inbound2_",
              value: values?.phone,
            },
            {
              objectTypeId: "0-1",
              name: "use_case_free_text_form",
              value: values?.useCaseFreeTextForm,
            },
            {
              objectTypeId: "0-1",
              name: "0-2/description",
              value: values["0-2/description"],
            },
          ].filter((x) => x.value),
        };
        if (hubspotutk) {
          Object.assign(requestPayload, {
            context: {
              pageUri: location?.href,
              pageName: "lex",
            },
          });
        }

        await axios.post(
          HUBSPOT_PROXY_URL,
          Object.assign(requestPayload, {
            portalId: "25381551",
            formId,
          })
        );
        /**
         * Append custom properties to Heap
         */
        if (window.heap) {
          window.heap.addUserProperties({
            marketing_email: values?.workEmail,
            use_case: values?.useCaseFreeTextForm,
          });
        }
        resetForm();
        localStorage.removeItem("workEmail");
        handleNext();
        if (!isEducationalEmail(values?.workEmail)) {
          const _res = await window.hero.submit({
            email: values?.workEmail,
            use_case: values?.useCaseFreeTextForm,
          });
          await window.hero.dialog.open(_res);
        } else {
          toast.success("Thanks for submitting the form.");
        }
      } else {
        setError("Please enter your work e-mail.");
      }
    } catch (error) {
      if (
        error?.response?.data &&
        error?.response?.data.errors &&
        error?.response?.data.errors[0]?.message
      ) {
        const [, errorMessage] =
          error?.response?.data?.errors[0]?.message.split(". ") || ["", ""];
        if (errorMessage) {
          setError(errorMessage ?? error?.response?.data?.errors[0]?.message);
        }
      } else {
        setError("Please enter your work e-mail.");
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <Formik
      initialValues={{
        workEmail: workEmail,
        useCaseFreeTextForm: "",
      }}
      validationSchema={RequestTrialSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        touched,
        values,
        setTouched,
        validateForm,
        setFieldValue,
        form,
        isValid,
        dirty,
        ...rest
      }) => {
        return (
          <Form className={`${formClass} mt-3.5`}>
            <div className="flex flex-col flex-1 w-full mt-4 lg:mt-2.5">
              <label className="text-gray-2500 placeholder:text-[#a8a4a4] text-sm font-inter tracking-tighter_2 font-medium mb-1.5">
                Work email
                <sup className="text-red-300">*</sup>
              </label>
              <Field
                name="workEmail"
                placeholder="Enter your work email"
                type="text"
                className="text-gray-600 placeholder:text-[#BFBFBF] text-base sm:text-sm font-inter tracking-tighter_2 px-5 py-2.5 font-normal rounded-lg placeholder-gray-2200 border-solid border-gray-2200 border"
              />
              {errors.workEmail && touched.workEmail ? (
                <small className="text-red-300">{errors.workEmail}</small>
              ) : null}
            </div>

            <span>
              <div className="flex flex-col mt-4 lg:mt-2.5">
                <label className="text-gray-2500 placeholder:text-[#a8a4a4] text-sm leading-5 font-inter font-medium mb-1.5">
                  What problems are you looking to solve?
                  <sup className="text-red-300">*</sup>
                </label>
                <Field
                  as="textarea"
                  name="useCaseFreeTextForm"
                  placeholder="Tell us more about what you’re trying to achieve"
                  className="text-gray-600  h-24 placeholder:text-[#BFBFBF] text-base sm:text-sm font-inter tracking-tighter_2 px-5 py-2.5 font-normal rounded-lg placeholder-gray-2200 border-solid border-gray-2200 border"
                />
                {errors.useCaseFreeTextForm && touched.useCaseFreeTextForm ? (
                  <small className="text-red-300">
                    {errors.useCaseFreeTextForm}
                  </small>
                ) : null}
              </div>

              <div className="flex gap-5">
                <button
                  type={"submit"}
                  disabled={loading || !(isValid && dirty)}
                  className={`disabled:cursor-not-allowed bg-[#5E44FF] font-semibold font-manrope tracking-tighter_3 border-[#5E44FF] text-white py-2 text-base shadow-lg rounded-full w-full mx-auto my-4 lg:mt-6 lg:mb-5 hover:border-[#5E24FF] hover:bg-[#5E24FF]`}
                  {...rest}
                >
                  <div className="flex justify-center items-center gap-2.5">
                    Submit
                    {loading && (
                      <div className="border border-white border-solid rounded-full size-4 animate-spin border-t-transparent" />
                    )}
                  </div>
                </button>
              </div>
            </span>
          </Form>
        );
      }}
    </Formik>
  );
};

const CHECKOUT_STEPS = [
  {
    name: "Enter your details",
    Component: (handleNext, formClass) => (
      <SingleEmailForm {...{ handleNext, formClass }} />
    ),
  },
  {
    name: "Tell us about your use case",
    Component: (handleNext, formClass) => (
      <DemoForm {...{ handleNext, formClass }} />
    ),
  },
];

const FormKusama = ({ location, slice }) => {
  const formClass = slice?.primary?.form_class?.text || "";
  const logo_marque_data = {
    items: slice?.items.map((x) => ({
      brand_image: { ...x.logo },
    })),
  };
  return (
    <article
      className={`relative ${slice?.primary?.theme === "dark" ? "dark_bg_gradient" : "bg-gray-2800"}`}
    >
      <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
        <section className="py-10 lg:py-20 lg:mx-11">
          <div className="flex items-center gap-5 lg:gap-10 direction_view">
            <section className="flex flex-col px-5 sm:px-9 pt-6 pb-10 lg:max-w-[482px]  w-full rounded-2.5xl form_shadow">
              <SlicesStepper
                stepsConfig={CHECKOUT_STEPS}
                privacyPolicy={slice?.primary?.form_bottom_text?.html}
                formClass={formClass}
                heading={slice?.primary?.form_heading?.text}
                description={slice?.primary?.form_description?.text}
              />
            </section>
            <section className="flex-col items-center w-full gap-10 py-20 desktop_view">
              <p
                className={`font-inter text-xl font-medium leading-7 tracking-tighter_1 ${slice?.primary?.theme === "dark" ? "text-white" : "text-gray-2500"}`}
              >
                {slice?.primary?.logo_heading?.text}
              </p>
              <div className="grid w-full grid-cols-3 xl:grid-cols-4 gap-y-10 gap-x-5">
                {slice?.items?.map((value, index) => (
                  <div
                    key={index}
                    className="flex justify-center items-center lg:max-h-[100px] lg:min-h-[100px] w-full"
                  >
                    <img
                      className="!object-scale-down ratio-[2/1] min-h-[57px] max-h-[57px]"
                      src={value?.logo?.url}
                      alt={value?.logo?.alt || "logo"}
                    />
                  </div>
                ))}
              </div>
            </section>
            <div className="flex-col items-center mobile_view">
              <p
                className={`font-inter text-base sm:text-xl font-semibold sm:font-medium leading-7 tracking-tight_1 sm:tracking-tighter_1 ${slice?.primary?.theme === "dark" ? "text-white" : "text-gray-2500"}`}
              >
                {slice?.primary?.logo_heading?.text}
              </p>
              <LogoMarquee logoArray={logo_marque_data} logoBackground="" />
            </div>
          </div>
        </section>
      </section>
    </article>
  );
};
export default FormKusama;

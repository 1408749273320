export const addAltAndLazyToImg = (html) => {
  return html.replace(/<img\b[^>]*>/g, (imgTag, index) => {
    // If alt is missing, add alt and loading attributes
    if (!imgTag.includes("alt=")) {
      return imgTag.replace(
        /\/?>/,
        ` alt="blog_image_${index}" loading="lazy"$&`
      );
    }
    // If loading="lazy" is missing, add it
    if (!imgTag.includes('loading="lazy"')) {
      return imgTag.replace(
        /\/?>/,
        ` loading="lazy"$&`
      );
    }
    return imgTag;
  });
};

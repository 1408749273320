
import "../../styles/components/CTAs.scss";

export const dataCentricWebinarCTA = () => {
    return ` <article class="flex sm:flex-row flex-col bg-white rounded-2xl overflow-hidden justify-around lg:px-7.5 px-4 sm:pt-9.5 pt-7 font-manrope gap-x-6 relative">
          <section class="absolute sm:text-xs text-2xs tracking-widest_1 uppercase text-purple-1500 bg-[#EEEBFF] px-16 sm:py-2.5 py-2 font-semibold -right-12 top-6 transform rotate-[30deg] z-10">
            Download now
          </section>
          <section class="sm:flex sm:justify-end md:w-64 sm:w-44">
            <div class="relative ">
                <div class="bg-gray-1500 xl:w-60 lg:w-48 md:w-60 w-40 lg:h-80 h-60 absolute xl:-top-3 lg:top-11 md:-top-2 top-0 sm:-left-2 z-0 sm:block hidden"></div>
                <img src="https://encord.cdn.prismic.io/encord/b1e11ff2-69fc-498e-aaae-d340c416eeb0_whitepaper+desktop+cover.svg" alt="whitepaper-cover" width={239} height={338} class="md:block hidden xl:w-60 w-full h-full relative"/>
                <img src="https://encord.cdn.prismic.io/encord/9c21c769-d5d0-4fc1-92a4-21db2a2ba1ed_whitepaper+tablet+cover.svg" alt="whitepaper-cover" width={168} height={238} class="md:hidden sm:block hidden w-40 h-full relative"/>
                <img src="https://encord.cdn.prismic.io/encord/c52e7d49-9235-42c0-8f2a-ad1aae2d7669_whitepaper+mobile+cover.svg" alt="whitepaper-cover" width={364} height={178} class="sm:hidden w-full"/>
              </div>
            </section>
          <section class="xl:max-w-[535px] lg:max-w-[300px] max-w-full sm:mt-7 mt-5 sm:pb-5 pb-7 sm:text-left text-center">
            <div class="uppercase tracking-widest_1 text-purple-1500 font-semibold sm:text-xs text-2xs">
              Available now
            </div>
            <div class="sm:mt-3 mt-1 lg:mb-5 sm:mb-4 mb-1 text-purple-1100 lg:text-3.25xl sm:text-2xl text-xl lg:leading-10 font-semibold tracking-tighter_3">
              Data-Centric AI White Paper
            </div>
            <div class="font-inter tracking-tighter_3 font-medium lg:text-base sm:text-sm text-xs text-gray-600 lg:mb-8 mb-6">
              <div class="sm:block hidden">Not sure what to prioritize in your Computer Vision Model Training?</div>
              <div>Read our White Paper on how to build Data-Centric Computer Vision Models.</div>
            </div>
            <a href="https://encord.com/lp/how-to-adopt-data-centric-ai-approach/" class="sm:px-4 px-3.5 py-2 bg-purple-1500 rounded-full cursor-pointer demo-cta">
              <span class="text-white sm:text-base text-sm">Download White Paper</span>
            </a>
          </section>
        </article>`;
  };
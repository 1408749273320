import blogDetailTable from "../../../components/BlogDetailTable";
import { activeCTA } from "../../../utilities/EncordCTAs/activeCTA";
import { apolloCTA } from "../../../utilities/EncordCTAs/apolloCTA";
import { BlogCtaTofu1 } from "../../../utilities/EncordCTAs/BlogCtaTofu1";
import { checkOutOnGithubVisual } from "../../../utilities/EncordCTAs/checkOutOnGithubVisual";
import { cvprCta } from "../../../utilities/EncordCTAs/cvprCta";
import { dataCentricWebinarCTA } from "../../../utilities/EncordCTAs/dataCentricWebinarCTA";
import { debugModelsEACTA } from "../../../utilities/EncordCTAs/debugModelsEACTA";
import { encordVsCta } from "../../../utilities/EncordCTAs/encordVsCta";
import { fixErrorsCTA } from "../../../utilities/EncordCTAs/fixErrorsCTA";
import { gptVisionCTA } from "../../../utilities/EncordCTAs/gptVisionCTA";
import { indexWebinarCTA } from "../../../utilities/EncordCTAs/indexWebinarCTA";
import { medicalCta } from "../../../utilities/EncordCTAs/medicalCta";
import { medicalCTALight } from "../../../utilities/EncordCTAs/medicalCTALight";
import { productActiveCTA } from "../../../utilities/EncordCTAs/productActiveCTA";
import { productSamCta } from "../../../utilities/EncordCTAs/productSamCta";
import { rlhfCTA } from "../../../utilities/EncordCTAs/rlhfCTA";
import { sam2WebinarCTA } from "../../../utilities/EncordCTAs/sam2WebinarCTA";
import { samCTA } from "../../../utilities/EncordCTAs/samCTA";
import { surgicalVideoCTA } from "../../../utilities/EncordCTAs/surgicalVideoCTA";
import { trainingDataCTA } from "../../../utilities/EncordCTAs/trainingDataCTA";
import { tryEncord } from "../../../utilities/EncordCTAs/tryEncord";
import { tryEncordCTAAnnotateVisual } from "../../../utilities/EncordCTAs/tryEncordCTAAnnotateVisual";
import { tryEncordTodayBar } from "../../../utilities/EncordCTAs/tryEncordTodayBar";
import { tryEncordVisual } from "../../../utilities/EncordCTAs/tryEncordVisual";
import { tryOnGithubBar } from "../../../utilities/EncordCTAs/tryOnGithubBar";
import { webinarVLMCTA } from "../../../utilities/EncordCTAs/webinarVLMCTA";
import {
  appendTargetBlank,
  replaceVideoTags,
} from "../../../utilities/helpers";

const removeQueryParamsFromGifs = (htmlString) => {
  const regex = /src="(https:\/\/.*\.gif)\?[^"]*"/g;
  return htmlString?.replace(regex, 'src="$1"');
};

// Function to generate a table HTML from data items, with rows split based on the "columns" count
function generateTableHTML(items, headingText, columns,highlightFirstRow) {
  let tableHTML = `<table style="border-collapse: collapse; width: 100%; border: 1px solid black;">
      ${headingText ? `<caption style="font-weight: bold; margin-bottom: 10px;">${headingText}</caption>` : ""}`;

  for (let i = 0; i < items.length; i += columns) {
    tableHTML += `<tr>`;
    for (let j = 0; j < columns; j++) {
      const item = items[i + j];
      tableHTML += item
        ? `<td style="border: 1px solid black; padding: 8px; ${highlightFirstRow && i === 0 && "font-weight: 800"}">${item.column.text}</td>`
        : `<td style="border: 1px solid black; padding: 8px;"></td>`;
    }

    tableHTML += `</tr>`;
  }

  tableHTML += `</table>`;
  return tableHTML;
}

// Function to replace all {{table(key)}} placeholders in HTML string
function replacePlaceholders(html, data) {
  const placeholderPattern = /{{table\(([^)]+)\)}}/g;

  return html.replace(placeholderPattern, (match, key) => {
    const tableData = data.find((item) => item.primary.key.text === key);
    if (!tableData) return match;

    return generateTableHTML(
      tableData.items,
      tableData.primary.heading.text,
      tableData.primary.columns,
      tableData.primary.highlight_first_row
    );
  });
}

export const handleCTA = (html, blog, data) =>
  replacePlaceholders(
    appendTargetBlank(replaceVideoTags(removeQueryParamsFromGifs(html))),
    data
  )
    ?.replaceAll("{{try_encord}}", tryEncord())
    ?.replaceAll("{{quiz_cta}}", `<div id="quiz_placeholder"></div>`)
    ?.replaceAll("{{try_encord_visual}}", tryEncordVisual())
    ?.replaceAll("{{check_out_on_github_visual}}", checkOutOnGithubVisual())
    ?.replaceAll("{{Encord_vs_CTA}}", encordVsCta())
    ?.replaceAll("{{medical_CTA}}", medicalCta())
    ?.replaceAll("{{medical_CTA_light}}", medicalCTALight())
    ?.replaceAll("{{surgical_video_CTA}}", surgicalVideoCTA())
    ?.replaceAll("{{SAM_CTA}}", samCTA())
    ?.replaceAll("{{debug_models_EA_CTA}}", debugModelsEACTA())
    ?.replaceAll("{{RLHF_CTA}}", rlhfCTA())
    ?.replaceAll("{{Webinar_VLM_CTA}}", webinarVLMCTA())
    ?.replaceAll("{{SAM2_webinar_CTA}}", sam2WebinarCTA())
    ?.replaceAll("{{index_webinar_CTA}}", indexWebinarCTA())
    ?.replaceAll(
      "{{Blog_CTA_BOFU_1}}",
      `<div id="bofu_cta1_placeholder"></div>`
    )
    ?.replaceAll(
      "{{Blog_CTA_BOFU_2}}",
      `<div id="bofu_cta2_placeholder"></div>`
    )
    ?.replaceAll(
      "{{Blog_CTA_BOFU_3}}",
      `<div id="bofu_cta3_placeholder"></div>`
    )
    ?.replaceAll(
      "{{overview_version}}",
      `<div id="overview_version_placeholder"></div>`
    )
    .replaceAll(
      "{{detailed_product_version}}",
      `<div id="detailed_product_version_placeholder"></div>`
    )
    ?.replaceAll(
      "{{compare_chart_version}}",
      `<div id="compare_chart_version_placeholder"></div>`
    )
    ?.replaceAll("{{Blog_CTA_TOFU_1}}", BlogCtaTofu1())
    ?.replaceAll(
      /{{Training_data_CTA(::(.*?))?}}/g,
      (match, hasText, capturedText) => {
        if (hasText && capturedText !== undefined) {
          return trainingDataCTA(capturedText);
        } else {
          return trainingDataCTA();
        }
      }
    )
    ?.replaceAll("{{fix_errors_CTA}}", fixErrorsCTA())
    ?.replaceAll("{{product_sam_cta}}", productSamCta())
    ?.replaceAll("{{GPT_Vision_CTA}}", gptVisionCTA())
    ?.replaceAll("{{Apollo_CTA}}", apolloCTA())
    ?.replaceAll("{{Active_CTA}}", activeCTA())
    ?.replaceAll("{{CTA_Product_Active}}", productActiveCTA())
    ?.replaceAll(
      "{{try_encord_CTA_annotate_visual}}",
      tryEncordCTAAnnotateVisual()
    )
    ?.replaceAll("{{try_encord_today_bar}}", tryEncordTodayBar())
    ?.replaceAll("{{try_on_github_bar}}", tryOnGithubBar())
    ?.replaceAll("{{table}}", blogDetailTable({ blog }))
    ?.replaceAll("{{blockquote_start}}", "<blockquote>")
    ?.replaceAll("{{blockquote_end}}", "</blockquote>")
    ?.replaceAll("{{data_centric_webinar_cta}}", dataCentricWebinarCTA())
    ?.replaceAll(
      "{{light_callout_start}}",
      `<div class="bg-gray-1900 px-4 py-5 rounded-lg">`
    )
    ?.replaceAll("{{light_callout_end}}", "</div>")
    ?.replaceAll(
      "{{gray_callout_start}}",
      `<div class="flex items-baseline gap-2.5 bg-gray-1600 border border-solid border-gray-1600 p-5 rounded-md">
        <img class="relative translate-y-0.5" src="https://encord.cdn.prismic.io/encord/Zk3PGCol0Zci9WSy_information.svg" width="17px" height="22px" alt="light-callout-cta"/>
        <span>
          `
    )
    ?.replaceAll("{{gray_callout_end}}", "</span></div>")
    ?.replaceAll(
      "{{product_hunt}}",
      `<iframe style="border: none;" src="https://cards.producthunt.com/cards/posts/400645?v=1" width="500" height="405" frameborder="0" scrolling="no" allowfullscreen></iframe>`
    )
    ?.replaceAll(
      "{{interactive_demo}}",
      `<div style="position: relative; padding-bottom: calc(60.14134275618375% + 41px); height: 0;"><iframe src="https://demo.arcade.software/9kgCKVJIyAVaqnJmwn8b?embed" title="app.encord.com" frameborder="0" loading="lazy" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;color-scheme: light;"></iframe></div>`
    )
    ?.replaceAll(
      "{{arcade_interactive_demo}}",
      `<div style="position: relative; padding-bottom: calc(59.76638546398443% + 41px); height: 0; width: 100%;"><iframe src="https://demo.arcade.software/wzDuj0S7OsZ4DwsO1HxH?embed&show_copy_link=true" title="Encord: Web App" frameborder="0" loading="lazy" webkitallowfullscreen mozallowfullscreen allowfullscreen allow="clipboard-write" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;color-scheme: light;"></iframe></div>`
    )
    ?.replaceAll("{{CVPR_CTA}}", cvprCta());

export const addAltToImgTags = (html) => {
  return html.replace(/<img\b(?![^>]*\balt=)[^>]*>/g, (imgTag) => {
    // Check if the img tag already has an alt attribute
    if (!imgTag.includes("alt=")) {
      // Add the alt attribute before the closing '>'
      return imgTag.replace(/\/?>/, ' alt="blog image"$&');
    }
    return imgTag;
  });
};
